@import url("https://fonts.googleapis.com/css?family=Montserrat");
body {
  background: #f1f1f2;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0 0 50px;
}

h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: uppercase;
}

h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 600;
  letter-spacing: 0;
}

h3 {
  letter-spacing: 2px;
  font-weight: 100;
}

textarea:focus {
  border-color: #37ffba;
  box-shadow: none;
  outline: 0 none !important;
}

label {
  display: block;
}

small {
  display: block;
}

input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus {
  border-color: #37ffba;
  box-shadow: none;
  outline: 0 none !important;
}

select:focus, .uneditable-input:focus {
  border-color: #37ffba;
  box-shadow: none;
  outline: 0 none !important;
}

p {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}

.btn-primary {
  border-color: white;
  background: black;
  color: white;
}

.btn-primary:hover {
  background: #37ffba;
  color: black;
}

.jumbotron {
  width: 100%;
  min-height: 700px;
  opacity: 1;
  display: table;
  width: 100%;
  position: relative;
  border-radius: 0px;
  color: white;
  margin: 0;
  overflow: hidden;
  webkit-clip-path: polygon(0 0%, 100% 0, 100% 100%, 0% 85%);
  clip-path: url(#poly);
}

.jumbotron .logo {
  position: relative;
  z-index: 1000;
  font-size: 2em;
  color: white;
  margin-left: 17px;
  padding-bottom: 75px;
  max-width: 120px;
  margin-left: 17px;
  padding-bottom: 1em;
  max-width: 120px;
  z-index: 100;
}

.jumbotron h2.title {
  color: #37ffba;
  font-size: 2.5em;
}

.jumbotron h3.subtitle {
  font-size: 18px;
  line-height: 25px;
  margin: 0;
}

#video-bg {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  background: #000;
  z-index: -1;
  webkit-clip-path: polygon(0 0%, 100% 0, 100% 100%, 0% 85%);
  clip-path: url(#poly);
  opacity: 1;
  overflow: hidden;
}

#black {
  position: absolute;
  right: 0;
  bottom: 0;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  background: rgba(26, 29, 37, 0.8);
}

#main-wrapper {
  margin-top: 0;
}

.main-content {
  padding-top: 25px;
  line-height: 25px;
}

.main-content .white-box {
  padding: 25px;
  margin: 35px 0;
  background: #fff;
}

.main-content ul {
  list-style: initial;
  margin-left: 15px;
}

.main-content .final-cta {
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
  margin-top: 4rem;
  padding-bottom: 8rem;
}

.form-container {
  background: #fff;
  padding: 25px;
  border-radius: 3px;
  margin-top: -100px;
}

.form {
  line-height: 25px;
  font-weight: 100;
}

.form-container div {
  margin: 20px 0;
}

.form-container input, .form-container select {
  border: none;
  border-bottom: 1px solid #ccc;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 12px;
  padding: 3px 0;
  border-radius: 0px;
  box-shadow: none;
  -webkit-appearance: none;
  color: #000;
  letter-spacing: 1px;
}

.form-container option {
  margin: 0;
}

.form-container label {
  display: none;
}

.form-container h4 {
  margin: 0;
}

.form-container .placeholder {
  color: #000 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-left: 5px;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
}

select option:first-child {
  display: none;
}

::-webkit-input-placeholder {
  color: #000 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
}

.form-container .btn-primary {
  background: #00af66;
  padding: 15px 0;
  border-radius: 3px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: white;
}

@media screen and (min-width: 2000px) {
  video#video-bg {
    display: none;
  }
  .jumbotron {
    webkit-clip-path: none;
    clip-path: none;
  }
}

@media screen and (max-width: 1200px) {
  video#video-bg {
    width: 150%;
  }
}

@media screen and (max-width: 900px) {
  video#video-bg {
    width: 300%;
  }
}

@media screen and (max-width: 768px) {
  .jumbotron {
    min-height: 100vh !important;
    webkit-clip-path: none;
    clip-path: none;
  }
  video#video-bg {
    display: none !important;
  }
  h2 {
    font-size: 20px;
  }
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #000 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #000 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

:-ms-input-placeholder {
  color: #000 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

footer {
  color: #999;
  position: absolute;
  background: #1a1d25;
  left: 0;
  bottom: 0;
  height: 40px;
  width: 100%;
  padding: 10px 0;
  font-size: 12px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .jumbotron {
    min-height: 590px;
    padding: 5px 15px;
  }
  .jumbotron h1 {
    font-size: 30px;
  }
  .logo {
    padding-top: 4rem;
  }
  .jumbotron h2 {
    font-size: 16px;
  }
  .form-container {
    margin-top: 20px;
  }
}

/**** END 320 and 480 *** */
